import authReducer from './authreducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import adminReducer from './adminreducer'
import transactionsReducer from './transactionsReducer'

const rootReducer = combineReducers({
    auth: authReducer,
    admin: adminReducer,
    transactions: transactionsReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,

})
export default rootReducer