import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/messaging';
var firebaseConfig = {
    apiKey: "AIzaSyBWqnZl4bSLs0c32IpQhhUvmXYw1PTENMQ",
    authDomain: "waka-85a44.firebaseapp.com",
    databaseURL: "https://waka-85a44-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "waka-85a44",
    storageBucket: "waka-85a44.appspot.com",
    messagingSenderId: "180393272509",
    appId: "1:180393272509:web:9356556ab857f12940c3d4",
    measurementId: "G-LLWDN292SK"
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async(setTokenFound) => {
    let currentToken = "";

    try {
        currentToken = await messaging.getToken({ vapidKey: publicKey });
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }

    return currentToken;
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
export default firebase