import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Loginscreen from './pages/SignIn'
import Dashboard from './pages/dashboard';
import Transactions from './pages/transactions';
import { connect } from 'react-redux'
import Main from './layout/Main';

import { onMessageListener } from "./config/fb";
import Notifications from "./notifications/Notifications";
import ReactNotificationComponent from "./notifications/ReactNotification";
import Settings from './pages/settings';
import Reports from './pages/reports';

function App(props) {

  const { profile, auth } = props;
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });


  onMessageListener()
    .then((payload) => {
      setShow(true);
      console.log('payload')
      console.log(payload);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    < BrowserRouter >
      {auth.isEmpty ? <div></div> : show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      {auth.isEmpty ? <div></div> :
        <Notifications profile={profile} auth={auth} />}
      <div className="App">
        <Switch>
          <Route exact path="/" render={props => <Loginscreen  {...props} />} />
          <Route path='/login' component={Loginscreen} />
          <Main>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/transactions" render={props => <Transactions companyid={profile.companyid} />} />
            <Route exact path="/reports" render={props => <Reports companyid={profile.companyid} />} />
            <Route exact path="/settings" render={props => <Settings companyid={profile.companyid} />} />
            {/* <Route exact path="/trans" component={Transactions} /> */}
            {/* <Route exact path="/clients" component={AllClients} />
            <Route exact path="/chamas" component={ChamaDetails} />
            <Route exact path="/devices" component={AssetDevices} />
            
            <Route exact path="/loanprofile" component={LoanProfile} /> */}
          </Main>
        </Switch>
      </div>
    </BrowserRouter >

  );
}

const mapStateToProps = (state) => {

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}


export default connect(mapStateToProps, null)(App)