import React, { useState } from 'react';
import {
    Card,
    Col,
    Typography, Button, Modal, Form, Input, InputNumber, message
} from "antd";
import {
    RightOutlined,
} from "@ant-design/icons";
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

function Dashboard(props) {
    const URL_BASE = 'https://us-central1-waka-85a44.cloudfunctions.net/';
    // const URL_BASE = 'http://localhost:5000/waka-85a44/us-central1/';
    const Api = axios.create({
        baseURL: URL_BASE
    });

    const { auth, company, profile } = props;
  
    const { Title } = Typography;

    const profileicon = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}
        >
            <path
                d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                fill="#fff"
            ></path>
            <path
                d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
                fill="#fff"
            ></path>
            <path
                d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
                fill="#fff"
            ></path>
            <path
                d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
                fill="#fff"
            ></path>
        </svg>,
    ];
    const cart = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
                fill="#fff"
            ></path>
        </svg>,
    ];
    const count = [
        {
            today: "Request Payments",
            title: "Receive payments today",
            icon: profileicon,
            bnb: "bnb2",
        },
        {
            today: "Safe Pay",
            title: "Make safe payments today",
            icon: cart,
            bnb: "bnb2",
        },
        {
            today: "B2B Pay",
            title: "Make business payments today",
            icon: cart,
            bnb: "bnb2",
        },
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const [form] = Form.useForm();
    const showModal = (val) => {
        console.log(val);
        if (val === 'Request Payments') {
            setIsModalOpen(true);
        } else {
            message.info('coming soon')
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = (values) => {
        try {
            setIsloading(true)
            message.loading('Requesting...');
            Api.post('stk_rDgGA90eVTWdxumnQs4tJhgw6g63', {
                params: {
                    amount: values.amount,
                    account: values.account,
                    phonenumber: values.phonenumber,
                    c2bconsumer_secret: company[0].c2bconsumer_secret,
                    c2bconsumer_key: company[0].c2bconsumer_key,
                    c2bmpesapassword: company[0].c2bmpesapassword,
                    c2bshortcode: company[0].c2bshortcode,
                    c2bTransactionType: "CustomerPayBillOnline",
                    c2bTransactionDesc: "Business Pay",
                    companyid: profile.companyid
                }
            }).then(data => {
                console.log("data")
                console.log(data)
                if (data.data.body.ResponseCode === "0") {
                    message.success('Processed');
                    setIsloading(false)
                    setIsModalOpen(false);
                    form.resetFields();
                }  else {
                    message.error('Error!');
                    setIsModalOpen(false);
                    setIsloading(false)
                }
            }).catch(function (error) {
                message.error('Server Error Occured!');
                setIsloading(false)
                setIsModalOpen(false);
            });
        } catch (e) {
            message.error('Error!');
            setIsloading(false)
            setIsModalOpen(false);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (!auth.uid) return <Redirect to='/login' />
    return (
        <>
            <Modal
                title="Request Payments"
                open={isModalOpen}
                footer={[]}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="phonenumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                    >
                        <Input placeholder="phone number" />
                    </Form.Item>

                    <Form.Item
                        name="account"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your account!',
                            },
                        ]}
                    >
                        <Input placeholder="account number" />
                    </Form.Item>
                    <Form.Item
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please input amount!',
                            },
                        ]}
                    >
                        <InputNumber placeholder="amount" />
                    </Form.Item>


                    <Form.Item>
                        {isloading ? <Button type="primary" loading >
                            Loading ...
                        </Button> : <Button style={{ backgroundColor: '#000000' }} type="primary" htmlType="submit">
                            Request
                        </Button>}
                    </Form.Item>
                </Form>
            </Modal>
            <div className="layout-content">

                {count.map((c, index) => (
                    <Col key={index} xs={24} md={12} sm={6} lg={6} xl={10} className="mb-12">
                        <Card bordered={false} className="criclebox card-info-2 h-full">
                            <div className="gradent h-full col-content">
                                <div className="card-content">
                                    <Title level={5}>{c.today}</Title>
                                    <p>
                                        {c.title}
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <Button onClick={() => showModal(c.today)} style={{ backgroundColor: '#ffffff' }} type="text">
                                        Go on ...
                                        <RightOutlined />
                                    </Button>

                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}

            </div>
        </>
    );
}
const mapStateToProps = (state) => {

    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        company: state.firestore.ordered.company,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(({ uid }) => [
        {
            collection: 'company',
        },
    ])
)(Dashboard)

