import React from 'react'
import moment from 'moment'
import * as XLSX from 'xlsx';
import { connect } from 'react-redux'
import { compose } from 'redux'

import {  Button  } from 'antd';


const samplejson = [];


const Xlsfile = (props) => {
    const { generatedData } = props;
    console.log(generatedData)

    generatedData && generatedData.forEach(e => {
        samplejson.push({
            "TransID": e.TransID,
            "TransTime": moment(e.TransTime).format("dddd, MMMM Do YYYY HH:mm:ss"),
            "names": e.names,
            "account": e.account,
            "amount": e.TransAmount,
            "balance": e.balance,
        })
    })

    const downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    };
    return (
        <Button onClick={() => downloadExcel(samplejson)}>
            Download As Excel
        </Button>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // createEmployee: (data) => dispatch(createEmployee(data)),
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps),)(Xlsfile);




