import React, { useState } from 'react';
import {
    Switch,
    List,

} from "antd";
import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'
import fb from '../config/fb';
import { getToken } from "../config/fb";

function Settings(props) {
    const { auth, notifsettings, profile } = props;
    console.log(notifsettings && notifsettings.some(item => item.id === auth.uid))
    const [isTokenFound, setTokenFound] = useState(false);
    // console.log(isTokenFound);
    const onChange = async (checked) => {
        let data;
        data = await getToken(setTokenFound);


        const db = fb.firestore();
        if (checked) {
            db.collection(`company/${profile.companyid}/notifsettings`).doc(auth.uid).set({
                fcmtoken: data,
            }).then(() => {
                console.log('ok')
            }).catch(err => {
                console.log(err)
            });
        } else {
            db.collection(`company/${profile.companyid}/notifsettings`).doc(auth.uid).delete().then(() => {
                console.log('ok')
            }).catch(err => {
                console.log(err)
            });
        }
    };
    const data = [
        {
            title: 'Receive Transaction Notifications (on)',
            action: <Switch defaultChecked onChange={onChange} />
        }
    ];
    const data2 = [
        {
            title: 'Receive Transaction Notifications(off)',
            action: <Switch onChange={onChange} />
        }
    ];
    if (!auth.uid) return <Redirect to='/login' />
    return (
        <>
            <div className="tabled">
                {notifsettings === null ? <></> : <List
                    size="large"
                    bordered
                    dataSource={notifsettings && notifsettings.some(item => item.id === auth.uid) ? data : data2}
                    renderItem={(item) => <List.Item>
                        <List.Item.Meta
                            title={item.title}
                        />
                        <div>{item.action}</div></List.Item>}
                />}
            </div>
        </>
    );
}
const mapStateToProps = (state, ownProps) => {

    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        notifsettings: state.firestore.ordered[`${ownProps.companyid}-notifsettings`],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(({ companyid }) => [

        {
            collection: 'company',
            doc: `${companyid}`,
            subcollections: [
                { collection: 'notifsettings' }
            ],
            storeAs: `${companyid}-notifsettings`
        },
    ])
)(Settings)


