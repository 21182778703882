import React, { useState, useEffect } from "react";
import { getToken } from "../config/fb";
import { updatetoken } from "../config/services";
import fb from '../config/fb';

const Notifications = (props) => {

    const { profile, auth } = props;

    const [isTokenFound, setTokenFound] = useState(false);

    // To load once
    useEffect(() => {
        let data;

        async function tokenFunc() {

            data = await getToken(setTokenFound);
            if (data) {
                const db = fb.firestore();                
                db.collection('admins').doc(auth.uid).update({
                    webfcmtoken: data,
                }).then(() => {
                    console.log('ok')
                }).catch(err => {
                    console.log(err)
                });

            }
            return data;
        }

        tokenFunc();
    }, [setTokenFound]);

    return <></>;
};

Notifications.propTypes = {};

export default Notifications;