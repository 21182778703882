import React, { useState } from 'react';
import {
    Row,
    Col,
    Card,
    Button, Table, Input,

} from "antd";
import {
    SearchOutlined,
} from "@ant-design/icons";


import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import { connect } from 'react-redux'

const columns = [
    {
        title: 'Txn Id',
        dataIndex: 'TransID',
        key: 'TransID',
    },
    {
        title: 'Date/Time',
        dataIndex: 'TransTime',
        key: 'TransTime',
        render: (a, b) => (
            <p>{isString(b.TransTime)}</p>
        )
    },
    {
        title: 'Names',
        dataIndex: 'names',
        key: 'names',
    },
    {
        title: 'Account',
        dataIndex: 'account',
        key: 'account',
    },
    {
        title: 'Phone',
        dataIndex: 'phonenumber',
        key: 'phonenumber',
    },
    {
        title: 'Amount(KSH)',
        dataIndex: 'TransAmount',
        key: 'amount',
    },
    {
        title: '#',
        dataIndex: 'Action',
        key: 'action',
        render: (_, record) => (
            <Button type="primary" style={{
                backgroundColor: '#000000',

            }}>View</Button>
        ),
    },
]
function isString(value) {

    if (typeof value === 'string' || value instanceof String) {
        return moment(value).format('DD/MM/YYYY HH:mm:ss')
    } else {
        let dateString = new Date(value.seconds * 1000 + value.nanoseconds / 1000000).toLocaleString();
        return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
    }
}
function Transactions(props) {
    const { auth, transactions, company, profile } = props;

    const [query, setQuery] = useState('');
    var currentcompany = {}
    currentcompany = company && company.filter(e => e.id == profile.companyid)[0]

    const keys = currentcompany && currentcompany.type == "till" ? ['TransID', 'names'] : ['TransID', 'account', 'names'];
    // function toIsoString(timestamp) {
    //     if (typeof timestamp === 'number' && !isNaN(timestamp)) {
    //         const date = new Date(timestamp);
    //         if (date instanceof Date && !isNaN(date.getTime())) {
    //             return date.toISOString();
    //         }
    //     }
    //     return null;
    // }

    const searchOnchange = (val) => {
        return val.filter((item) => keys.some((param) => item[param].toLowerCase().includes(query)));
    };

    function reverse(array) {

        return array.sort((a, b) => extracttime(b.TransTime) - extracttime(a.TransTime));
        // return array.map((item, idx) => array[array.length - 1 - idx])
    }
    function extracttime(value) {

        if (typeof value === 'string' || value instanceof String) {
            return new Date(value).getTime()
        } else {
            let dateString = new Date(value.seconds * 1000 + value.nanoseconds / 1000000).toLocaleString();
            return new Date(dateString).getTime();
        }
    }
    if (!auth.uid) return <Redirect to='/login' />
    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox space mb-24"
                            title="Transactions"
                            extra={
                                <>
                                    <Input
                                        className="header-search"
                                        placeholder="Search here..."
                                        onChange={(e) => setQuery(e.target.value)}
                                        prefix={<SearchOutlined />}
                                    />
                                </>
                            }
                        >
                            <div className="table-responsive">
                                {transactions == null ? <div></div> :
                                    <Table
                                        columns={columns}
                                        dataSource={reverse(searchOnchange(transactions))}
                                        className="ant-border-space" />}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}
const mapStateToProps = (state, ownProps) => {

    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        company: state.firestore.ordered.company,
        transactions: state.firestore.ordered[`${ownProps.companyid}-trans`],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(({ companyid }) => [
        {
            collection: 'company',
        },
        {
            collection: 'company',
            doc: `${companyid}`,
            subcollections: [
                { collection: 'transactions' }
            ],
            storeAs: `${companyid}-trans`
        },
    ])
)(Transactions)


