const initState = {
    transactions: []
}
const transactionsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UP':
            console.log(' yes');
            return {
                ...state,
                sessionerror: 'Login failed'
            }

        case 'DOWN':
            console.log(' err');
            return {
                ...state,
                sessionerror: null
            }
        default:
            return state
    }
}
export default transactionsReducer