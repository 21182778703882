import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import {  createStore, applyMiddleware, compose } from 'redux';

import { Provider } from 'react-redux';
import rootReducer from './store/rootreducer';
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import fb from './config/fb';

const store = createStore(rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(fb),
    reactReduxFirebase(fb, { userProfile: 'admins', useFirestoreForProfile: true, attachAuthIsReady: true }),
  )
);

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
});

reportWebVitals();
