const initState = {
    users: []
}
const adminReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SIGNUP_SUCCESS':
            console.log(' error');
            return {
                ...state,
                sessionerror: 'Login failed'
            }

        case 'SIGNUP_ERROR':
            console.log(' success');
            return {
                ...state,
                sessionerror: null
            }
        default:
            return state
    }
}
export default adminReducer