import React, { useState } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../config/auth'
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  message,
} from 'antd';
import signinbg from "../assets/images/signin.png";
import { Redirect } from 'react-router-dom';


const { Title } = Typography;
const { Footer, Content } = Layout;
const Loginscreen = (props) => {
  const [isloading, setIsLoading] = useState(false)
  const { auth, authError, signIn } = props;
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = values => {
    setIsLoading(true)
    signIn(values)
      .then(v => {
        setIsLoading(false)
        message.success(
          'Done...'
        );
      }).catch(err => {

        setIsLoading(false)
        message.error(
          'there is a problem'
        );
      });
  }



  if (auth.uid) return <Redirect to='/dashboard' />

  return (
    <>
      <Layout className="layout-default layout-signin">

        <Content style={{ marginTop: 100 }} className="signin">

          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input placeholder="Password" />
                </Form.Item>



                <Form.Item>
                  {isloading ? <Button type="primary" loading >
                    Loading ...
                  </Button> : <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", backgroundColor: '#000000' }}
                  >
                    SIGN IN
                  </Button>}
                </Form.Item>

              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer>

          <p className="copyright">
            Copyright © 2023 Waka by <a href="#pablo">MACANGIRA</a>
          </p>
        </Footer>
      </Layout>
    </>
  );

}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loginscreen)