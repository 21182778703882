import React, { useState } from 'react';
import {
    Row,
    Col,
    Card,
    Table, DatePicker

} from "antd";
import Xlsfile from '../componets/xls';

import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import { connect } from 'react-redux'

const { RangePicker } = DatePicker;
const columns = [
    {
        title: 'Txn Id',
        dataIndex: 'TransID',
        key: 'TransID',
    },
    {
        title: 'Date/Time',
        dataIndex: 'TransTime',
        key: 'TransTime',
        render: (a, b) => (
            <p>{moment(b.TransTime).format('DD/MM/YYYY HH:mm:ss')}</p>
        )
    },
    {
        title: 'Names',
        dataIndex: 'names',
        key: 'names',
    },
    {
        title: 'Account',
        dataIndex: 'account',
        key: 'account',
    },
    {
        title: 'Amount(KSH)',
        dataIndex: 'TransAmount',
        key: 'TransAmount',
    },
    // {
    //     title: '#',
    //     dataIndex: 'Action',
    //     key: 'TransID',
    //     render: (_, record) => (
    //         <Button type="primary" style={{
    //             backgroundColor: '#000000',

    //         }}>View</Button>
    //     ),
    // },
]
function Reports(props) {
    const { auth, transactions } = props;

    const [filterTransaction, setfilterTransaction] = useState([]);


    const selectDate = (val) => {
        var results = transactions.filter((tr, index) =>
            new Date(tr.TransTime).getTime() >= new Date(val[0]).getTime() && new Date(tr.TransTime).getTime() <= new Date(val[1]).getTime());
        setfilterTransaction(results);
    };

    if (!auth.uid) return <Redirect to='/login' />
    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox space mb-24"
                            title="Reports"
                            extra={
                                <>

                                    <RangePicker
                                        onChange={selectDate}
                                        showTime={{
                                            format: 'HH:mm',
                                        }} style={{ marginRight: 10 }}
                                        dateRender={(current) => {

                                            return (
                                                <div className="ant-picker-cell-inner" >
                                                    {current.date()}
                                                </div>
                                            );
                                        }}
                                    />

                                    {filterTransaction.length === 0 ? <></> : <Xlsfile
                                        generatedData={filterTransaction}
                                    />}

                                </>
                            }
                        >
                            <div className="table-responsive">
                                {transactions === null ? <div></div> :
                                    <Table
                                        columns={columns}
                                        dataSource={filterTransaction}
                                        className="ant-border-space" />}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}
const mapStateToProps = (state, ownProps) => {

    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        transactions: state.firestore.ordered[`${ownProps.companyid}-trans`],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(({ companyid }) => [

        {
            collection: 'company',
            doc: `${companyid}`,
            subcollections: [
                { collection: 'transactions' }
            ],
            storeAs: `${companyid}-trans`
        },
    ])
)(Reports)


