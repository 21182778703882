import fb from './fb';
export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(
                credentials.email,
                credentials.password
            ).then((v) => {
                const db = fb.firestore()
                console.log(v.user.uid)
                db.collection(`admins`).doc(v.user.uid).get().then((querySnapshot) => {
                    console.log(querySnapshot.data())
                    console.log(querySnapshot.exists)
                    if (querySnapshot.exists) {
                        dispatch({ type: 'LOGIN_SUCCESS' });
                        return resolve('ok')
                    } else {
                        firebase.auth().signOut().then(() => {
                            dispatch({ type: 'SIGNOUT_SUCCESS' })
                            return resolve('signedout')
                        });
                    }
                })
            }).catch((err) => {
                console.log(err);
                dispatch({ type: 'LOGIN_ERROR', err });
                return reject('error')
            });
        })
    }
}



export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        console.log('sign out')
        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS' })
        });
    }
}
export const signUp = (newUser) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then(resp => {
            return firestore.collection('admins').doc(resp.user.uid).set({
                ...newUser
            });

        }).then(resp => {

        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' });
        }).catch((err) => {
            dispatch({ type: 'SIGNUP_ERROR', err });
        });
    }
}